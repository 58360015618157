import _react from "react";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var react_1 = _react;
var isBrowser = typeof window !== "undefined";

function getScrollPosition() {
  return isBrowser ? {
    x: window.pageXOffset,
    y: window.pageYOffset
  } : {
    x: 0,
    y: 0
  };
}

function useScrollPosition() {
  var _a = react_1.useState(getScrollPosition()),
      position = _a[0],
      setScrollPosition = _a[1];

  react_1.useEffect(function () {
    var requestRunning = null;

    function handleScroll() {
      if (isBrowser && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(function () {
          setScrollPosition(getScrollPosition());
          requestRunning = null;
        });
      }
    }

    if (isBrowser) {
      window.addEventListener("scroll", handleScroll);
      return function () {
        return window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  return position;
}

exports.useScrollPosition = useScrollPosition;

function useScrollXPosition() {
  var x = useScrollPosition().x;
  return x;
}

exports.useScrollXPosition = useScrollXPosition;

function useScrollYPosition() {
  var y = useScrollPosition().y;
  return y;
}

exports.useScrollYPosition = useScrollYPosition;
export default exports;
export const __esModule = exports.__esModule;
const _useScrollPosition = exports.useScrollPosition,
      _useScrollXPosition = exports.useScrollXPosition,
      _useScrollYPosition = exports.useScrollYPosition;
export { _useScrollPosition as useScrollPosition, _useScrollXPosition as useScrollXPosition, _useScrollYPosition as useScrollYPosition };